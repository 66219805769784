footer {
	.container {
		border-top: 1px solid #c7cdd8;
		padding-top: 30px;

		.copyright {
			text-decoration: none;
			color: #000;
		}

		.row {
			justify-content: space-between;
		}

		.links {
			a {
				color: #000;
				text-decoration: none;
			}
		}
	}
}

.navbar-custom-container {
	background: linear-gradient(74.43deg, #e82a49 0%, #f46d40 100%);
}
