.qr-info-page {
	width: 100%;
	margin: 40px auto;

	.no-information {
		text-align: center;
		color: #000;
		font-size: 32px;
	}

	.loader {
		margin: 0 auto;
		display: flex !important;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.content {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		max-width: 100%;

		img {
			max-width: 100%;

			@media (max-width: 992px) {
				max-width: 80%;
			}
		}

		.text {
			text-align: center;
			font-size: 24px;
			margin-top: 10px;
		}
	}
}

.qr-info {
	.navbar-custom-container {
		background: linear-gradient(74.43deg, #e82a49 0%, #f46d40 100%);
	}
}
