@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

:root {
	--max-width: 1100px;
	--border-radius: 12px;
	--font-mono: "Poppins", ui-monospace, Menlo, Monaco, "Cascadia Mono",
		"Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace",
		"Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New",
		monospace;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	color: rgb(var(--foreground-rgb));
	background: linear-gradient(
			to bottom,
			transparent,
			rgb(var(--background-end-rgb))
		)
		rgb(var(--background-start-rgb));
}

.homepage {
	* {
		font-family: Poppins !important;
	}
}

.custom-btn {
	&:hover {
		color: #000;
	}
}
