.about-image-short {
	max-width: 47%;
	float: left;
	max-height: 210px;
	border-radius: 10px;

	@media (max-width: 991px) {
		max-width: 100%;
		max-height: none !important;
		height: 14rem !important;
	}
}

.about-image-long {
	max-width: 100%;
	float: left;
	max-height: 210px;
	border-radius: 10px;
	object-fit: cover;
	width: 100%;
	object-position: top;

	@media (max-width: 991px) {
		display: none;
	}
}
