.guide_section{
    max-width: 100vw;
    padding: 0;
}

.guide_section_box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70rem;
    padding: 2rem 0;
    margin: 0 auto;
    background-color: #fff;
}

.step_box {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}